





















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    navigation: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: false,
      default: () => {
        return {
          duration: 300,
          offset: 0,
          easing: 'easeInOutCubic',
        }
      },
    },
  },
})
